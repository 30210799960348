import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from 'gatsby'

import { usePlaceholder } from "../../hooks/placeholder"
import { BACKGROUND_COLOR, MAIN_COLOR, SECONDARY_COLOR } from "../../variables"
import Container from "../Container"
import SectionTitle from "../SectionTitle"
import { getPosts } from "../../services/api/posts"

const BlogSection = styled.div`
  background-color: ${BACKGROUND_COLOR};
  padding: 4.5454em 0;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 768px) {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const Card = styled.div`
  text-align: center;
  @media (min-width: 768px) {
    margin-bottom: 4.5454rem;
  }
`

const CardImage = styled.div`
  width: 135px;
  height: 135px;
  margin: 0 auto 1.45rem auto;
  background-image: ${props => (props.src ? 'url("' + props.src + '")' : "")};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media all and (min-width: 768px) {
    width: 100%;
    height: 280px;
  }
`

const StyledImg = styled(Img)`
  max-width: 150px;
  border-radius: 50%;
  margin: 0 auto 1.45rem auto;
`

const CardTitle = styled.h3`
  font-size: 1.1818em;
  font-family: "Quando", serif;
  margin-bottom: 0.909rem;
`

const CardText = styled.p`
  margin: 0;
`

const StyledImgLink = styled(Link)`
  transition: opacity .3s ease-in-out;
  display: block;

  &:hover {
    opacity: .7;
  }
`

const StyledTextLink = styled(Link)`
  color: ${MAIN_COLOR};
  text-decoration: none;
  transition: color .2s ease-in-out;

  &:hover {
    color: ${SECONDARY_COLOR};
  }
`

const Blog = () => {
  const [posts, setPosts] = useState([])
  const { placeholder } = usePlaceholder()

  useEffect(() => {
    loadPosts()
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const wow = require("wowjs")
      new wow.WOW({ live: false, mobile: false }).init()
    }
  })

  const loadPosts = async () => {
    try {
      const result = await getPosts()
      setPosts(result || [])
    } catch (error) {
      console.error(error)
    }
  }

  return (
    posts.length > 0 && (
      <BlogSection id="blog">
        <Container>
          <SectionTitle>Blog</SectionTitle>
          <Grid>
            {posts.map(({ id, featuredImage, title, summary }, idx) => (
              <Card
                key={idx}
                className="wow fadeIn"
                data-wow-delay={`${idx * 0.1}s`}
              >
                <StyledImgLink to={`/blog/${id}`}>
                  {featuredImage ? (
                    <CardImage src={featuredImage} />
                  ) : (
                    <StyledImg fluid={placeholder} />
                  )}
                </StyledImgLink>
                <StyledTextLink to={`/blog/${id}`}>
                  <CardTitle>{title}</CardTitle>
                </StyledTextLink>

                <CardText>{summary}</CardText>
              </Card>
            ))}
          </Grid>
        </Container>
      </BlogSection>
    )
  )
}

export default Blog
