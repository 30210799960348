import { Link as GatsbyLink } from "gatsby"
import React from "react"
import styled from "styled-components"
import { MAIN_COLOR, SECONDARY_COLOR } from "../../variables"
import Container from "../Container"


const ContactSection = styled.section`
  background-color: ${MAIN_COLOR};
  padding: 4.5454em 0;
`

const StyledContainer = styled(Container)`
  max-width: 720px;
  text-align: center;
`

const SectionTitleContainer = styled.div`
  width: 100%;
  text-align: center;
`

const HR = styled.div`
  background-color: ${SECONDARY_COLOR};
  width: 80px;
  height: 10px;
  margin-bottom: 1.59rem;
  display: inline-block;
`

const SectionTitle = styled.h2`
  font-size: 1.8181em;
  margin-bottom: 1.136rem;
  color: #fff;
`

const ContactText = styled.p`
  color: #727df2;
  font-family: "Quando", serif;
  margin-bottom: 3.6363rem;
`

const ContactButton = styled(GatsbyLink)`
  display: inline-block;
  font-size: 1.0909em;
  font-weight: 700;
  background-color: ${SECONDARY_COLOR};
  color: #fff;
  padding: 15px 60px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0ce8a;
    box-shadow: 0 2px 14px 0 #0f1f65;
  }
`

const Contact = () => {
  return (
    <ContactSection id="kapcsolat">
      <StyledContainer>
        <SectionTitleContainer>
          <HR className="wow fadeIn" />
          <SectionTitle className="wow fadeIn" data-wow-delay=".2s">
            Máshogy szeretnék segíteni!
          </SectionTitle>
        </SectionTitleContainer>
        <ContactText className="wow fadeIn" data-wow-delay=".3s">
          Ebben az esetben vedd fel velünk a kapcsolatot, hiszen segíthetsz
          eszközökkel vagy akár más szolgáltatásokkal is.
        </ContactText>
        <ContactButton
          className="wow fadeIn"
          data-wow-delay=".4s"
          to="/felajanlas"
        >
          Felajánlás
        </ContactButton>
      </StyledContainer>
    </ContactSection>
  )
}

export default Contact
