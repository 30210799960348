import React from "react"
import styled from "styled-components"

import JoinUs from "../images/join.svg"
import { SECONDARY_COLOR } from "../variables"
import { useSiteMeta } from "../hooks/siteMeta"

const JoinUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fbf9f9;
  max-height: 300px;
  padding: 40px 35px;
  text-align: center;
  border-radius: 10px;
  grid-column: span 2;

  @media (min-width: 768px) {
    grid-column: initial;
  }
`

const JoinUsIconContainer = styled.div`
  width: 40px;
  margin-bottom: 1.1818rem;
`

const JoinUsTitle = styled.h3`
  font-size: 1.1818em;
  font-family: "Quando", serif;
  margin-bottom: 1.59rem;
  line-height: 1.35;
`

const JoinUsButton = styled.a`
  font-size: 1.0909em;
  font-weight: 700;
  background-color: ${SECONDARY_COLOR};
  color: #fff;
  padding: 15px 40px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  border-radius: 10px;
  display: inline-block;

  &:hover {
    background-color: #f0ce8a;
    box-shadow: 0 2px 14px 0 #d2b6ab;
  }
`

const JoinCard = ({ text, buttonLabel }) => {
  const { email } = useSiteMeta()
  return (
    <JoinUsContainer className="wow fadeInRight">
      <JoinUsIconContainer>
        <JoinUs />
      </JoinUsIconContainer>
      <JoinUsTitle>{text}</JoinUsTitle>
      <JoinUsButton href={`mailto:${email}`}>
        {buttonLabel}
      </JoinUsButton>
    </JoinUsContainer>
  )
}

export default JoinCard
