import React from "react"
import styled from "styled-components"
import { MAIN_COLOR, SECONDARY_COLOR } from "../../variables"

import Container from "../Container"

const GoalsSection = styled.section`
  background-color: ${MAIN_COLOR};
  padding-top: 4.5454em;
  padding-bottom: 5.4545em;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`

const StyledH2 = styled.h2`
  color: ${SECONDARY_COLOR};
  font-size: 1.8181em;
  line-height: 1.35;
  margin-bottom: 1.8181rem;
`

const Text = styled.p`
  color: #fff;
  
  @media (min-width: 768px) {
    grid-column: span 2;
    margin: 0 4.5454rem;
  }
`

const Goals = () => {
  return (
    <GoalsSection id="celunk">
      <Container>
        <Grid>
          <StyledH2 className="wow fadeInLeft">
            Miért segítsünk együtt a Hősöknek?
          </StyledH2>
          <Text className="wow fadeInRight">
            Hazánkat is elérte a koronavírus, mellyel az egészségügyi dolgozók
            próbálják felvenni a harcot. Ők azok, akik a beteg szeretteinket
            ellátják, meggyógyítják annak érdekében, hogy még hosszú éveket
            tölthessünk együtt. Mérhetetlenül hálásak vagyunk és ennek
            köszönhetően szeretnénk támogatni őket, hogy legalább az étkezésük
            megoldott legyen. Maradjunk otthon és segítsünk így együtt. Mutassuk
            meg mire is vagyunk képesek, hiszen ők is megteszik ezt értünk nap,
            mint nap!
          </Text>
        </Grid>
      </Container>
    </GoalsSection>
  )
}

export default Goals
