import Img from "gatsby-image"
import GatsbyLink from "gatsby-link"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { BACKGROUND_COLOR, BACKGROUND_COLOR2, MAIN_COLOR, SECONDARY_COLOR } from "../../variables"
import Bubble from "../Bubble"
import Container from "../Container"

const portions = [1, 5, 10, 20]

const DonateSection = styled.section`
  background-color: ${BACKGROUND_COLOR};
  padding: 4.5454em 0;
`

const SectionTitle = styled.h2`
  font-size: 1.8181em;
  max-width: 800px;
  line-height: 1.35;
  margin-bottom: 2.2727rem;
  
  @media (min-width: 768px) {
    margin-bottom: 5.4545rem;
  }
`

const AltTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.04rem;
`

const AltTitle = styled.h3`
  font-family: "Quando", serif;
  margin-left: 1.5909rem;
  margin-bottom: 0;
  font-size: 1em;
`

const Grid = styled.div`
  display: grid;
  margin-bottom: 3.1818rem;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  
  @media (min-width: 769px) {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.18rem 0;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;
  ${props =>
    props.active
      ? "border: 3px solid" + MAIN_COLOR + ";"
      : "border: 3px solid #fff;"}
`

const CardImage = styled.div`
  max-width: 180px;
  width: initial;
  min-width: 120px;
  margin-bottom: 2.2727rem;

  img {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    width: 180px;
  }
`

const CardNumber = styled.span`
  font-family: "Quando", serif;
  font-size: 1.3636em;
  margin-bottom: 0.4545rem;
`

const CardText = styled.p`
  font-family: "Quando", serif;
  font-size: 0.909rem;
  margin-bottom: 0.909rem;
`

const CardPrice = styled.span`
  font-family: "Quando", serif;
  font-size: 1.0909em;
  margin-bottom: 0;
  color: ${SECONDARY_COLOR};
`

const DonateCard = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 2.2727rem 0;
  text-align: center;
`

const DonateCardText = styled.p`
  font-family: "Quando", serif;
  font-size: 1.1818em;
  margin-bottom: 2.045rem;
`

const Link = styled(GatsbyLink)`
  display: inline-block;
  font-size: 1.0909em;
  font-weight: 700;
  color: #fff;
  box-shadow: 0px 2px 16px 0px #8393d7;
  background-color: ${MAIN_COLOR};
  padding: 20px 40px;
  max-width: 290px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #333b9c;
    box-shadow: 0 2px 14px 0 #d2b6ab;
  }
`

const CardInput = styled.div`
  display: flex;
  max-height: 35px;
  max-width: 165px;
`

const InputHandler = styled.div`
  width: 35px;
  height: 35px;
  background-color: ${BACKGROUND_COLOR2};
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }
`

const StyledInput = styled.input`
  width: 100%;
  max-width: 65px;
  color: ${MAIN_COLOR};
  font-size: 25px;
  text-align: center;
  font-family: "Quando", serif;
`

const Donate = ({ onePortion, fivePortion, tenPortion, xPortion }) => {
  const [selectedCard, setSelectedCard] = useState(1)
  const [selectedValue, setSelectedValue] = useState(5)
  const [xInput, setXInput] = useState(20)
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (selectedCard === 3) {
      setSelectedValue(xInput)
    }
  }, [xInput, selectedCard])

  const onCardClick = (id) => () => {
    if (id === 3) {
      setSelectedCard(id)
      return setSelectedValue(xInput)
    }
    setSelectedValue(portions[id])
    setSelectedCard(id)
  }

  const onRemovePortionClick = () => {
    setSelectedCard(3)
    if (xInput <= 1) return
    setXInput(xInput - 1)
  }

  const onAddPortionClick = () => {
    setSelectedCard(3)
    setXInput(xInput + 1)
  }

  return (
    <DonateSection id="adomanyozas">
      <Container>
        <SectionTitle className="wow fadeInRight">
          A felajánlásodat pár egyszerű lépésben megteheted
        </SectionTitle>
        <AltTitleContainer className="wow fadeInRight">
          <Bubble>1</Bubble>
          <AltTitle>Válaszd ki a felajánlani kívánt mennyiséget</AltTitle>
        </AltTitleContainer>

        <Grid>
          <Card
            active={0 === selectedCard}
            onClick={onCardClick(0)}
            className="wow fadeInLeft"
            data-wow-delay=".2s"
          >
            <CardImage>
              <Img fluid={onePortion} />
            </CardImage>
            <CardNumber>1</CardNumber>
            <CardText>adag étel</CardText>
            <CardPrice>1000 Ft</CardPrice>
          </Card>
          <Card
            active={1 === selectedCard}
            onClick={onCardClick(1)}
            className="wow fadeInLeft"
            data-wow-delay=".4s"
          >
            <CardImage>
              <Img fluid={fivePortion} />
            </CardImage>
            <CardNumber>5</CardNumber>
            <CardText>adag étel</CardText>
            <CardPrice>5000 Ft</CardPrice>
          </Card>
          <Card
            active={2 === selectedCard}
            onClick={onCardClick(2)}
            className="wow fadeInLeft"
            data-wow-delay=".6s"
          >
            <CardImage>
              <Img fluid={tenPortion} />
            </CardImage>
            <CardNumber>10</CardNumber>
            <CardText>adag étel</CardText>
            <CardPrice>10000 Ft</CardPrice>
          </Card>
          <Card
            active={3 === selectedCard}
            onClick={onCardClick(3)}
            className="wow fadeInLeft"
            data-wow-delay=".8s"
          >
            <CardImage>
              <Img fluid={xPortion} />
            </CardImage>
            <CardNumber>
              <CardInput>
                <InputHandler
                  onClick={onRemovePortionClick}
                >
                  -
                </InputHandler>
                <StyledInput value={xInput} disabled />
                <InputHandler
                  onClick={onAddPortionClick}
                >
                  +
                </InputHandler>
              </CardInput>
            </CardNumber>
            <CardText>adag étel</CardText>
            <CardPrice>{`${xInput * 1000} Ft`}</CardPrice>
          </Card>
        </Grid>

        <AltTitleContainer className="wow fadeInRight">
          <Bubble>2</Bubble>
          <AltTitle>Ellenőrizd az összeget és a darabszámot, majd kattints a "Segítek és Fizetek" gombra.</AltTitle>
        </AltTitleContainer>

        <DonateCard className="wow fadeIn">
          <DonateCardText>
            {`${selectedValue} adag étellel (${selectedValue * 1000} Ft) szeretnék hozzájárulni.`}
          </DonateCardText>
          <Link
            to={`/adomanyozas?quantity=${selectedValue}`}
          >Segítek és Fizetek</Link>
        </DonateCard>
      </Container>
    </DonateSection>
  )
}

export default Donate
