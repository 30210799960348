import axios from "./axios"

export const getPosts = async () => {
  const {data} = await axios.get().get(`/posts?categoryId=2`)

  return data.reduce((acc, post) => ([
    ...acc,
    {
      id: post.id,
      featuredImage: post.featured_images,
      title: post.title,
      summary: post.summary,
    }
  ]), [])
}

export const getPost = async (id) => {
  const { data } = await axios.get().get(`/posts/${id}`)

  return data
}
