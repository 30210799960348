import React, { useState, useEffect } from "react"
import styled from "styled-components"

import Container from "../Container"
import Cards from "../Cards"
import SectionTitle from "../SectionTitle"
import { getRestaurants } from "../../services/api"
import JoinCard from "../JoinCard"

const PartnersSection = styled.section`
  padding: 4.5454em 0;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 768px) {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const Partners = () => {
  const [restaurants, setRestaurants] = useState([])

  useEffect(() => {
    loadRestaurants()
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const wow = require('wowjs')
      new wow.WOW({ live: false, mobile: false }).init()
    }
  })

  const loadRestaurants = async () => {
    try {
      const result = await getRestaurants()
      setRestaurants(result.data || [])
    } catch (error) {
      console.error(error)
    }
  }

  return (
    restaurants.length > 0 && (
      <PartnersSection id="partnereink">
        <Container>
          <SectionTitle>Partnereink</SectionTitle>
          <Grid>
            <Cards elements={restaurants} />
            <JoinCard text='Szeretnék támogató lenni!' buttonLabel='Csatlakozom'/>
          </Grid>
        </Container>
      </PartnersSection>
    )
  )
}

export default Partners
