import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"
import { SECONDARY_COLOR } from "../../variables"
import Container from "../Container"

const HeroSection = styled.section`
  padding: 30px 0;

  @media (min-width: 768px) {
    height: 80vh;
    padding: 0;
  }
`

const StyledContainer = styled(Container)`
  height: inherit;
`

const Grid = styled.div`
  height: inherit;
  display: grid;
  grid-gap: 1.8181em;
  align-items: center;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`

const HeroContainer = styled.div`
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`

const Button = styled.a`
  font-size: 1.0909em;
  font-weight: 700;
  background-color: ${SECONDARY_COLOR};
  color: #fff;
  padding: 15px 40px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;

  &:hover {
    background-color: #f0ce8a;
    box-shadow: 0 2px 14px 0 #d2b6ab;
  }
`

const StyledImg = styled(Img)`
  grid-row: 1;
  width: 250px;
  justify-self: center;

  @media (min-width: 768px) {
    width: 100%;
    justify-self: flex-start;
    grid-row: initial;
  }
`

const HeroTitle = styled.h1`
  font-size: 2.2727em;
  line-height: 1.35;
  margin-bottom: 2.7272rem;
`

const Hero = ({ heroImage }) => {
  return (
    <HeroSection id="hero">
      <StyledContainer>
        <Grid>
          <HeroContainer>
            <HeroTitle className="wow fadeIn">
              Támogasd a Hősöket, hogy küzdhessenek érted és értem.
            </HeroTitle>
            <Button
              href='#hogyan-mukodik'
              className="wow fadeIn"
            >
              Segítek!
            </Button>
          </HeroContainer>
          <StyledImg className="wow fadeIn" fluid={heroImage} fadeIn={false} />
        </Grid>
      </StyledContainer>
    </HeroSection>
  )
}

export default Hero
