import styled from 'styled-components'
import { SECONDARY_COLOR } from '../variables'

const Bubble = styled.div`
  min-height: 60px;
  max-height: 60px;
  min-width: 60px;
  font-family: 'Quando', serif;
  font-size: 1.3636em;
  background-color: ${SECONDARY_COLOR};
  color: #fff;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
`

export default Bubble