import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { BACKGROUND_COLOR } from "../../variables"
import Container from "../Container"
import SectionTitle from "../SectionTitle"
import { getPartners } from '../../services/api'
import Cards from "../Cards"
import JoinCard from "../JoinCard"

const HealthCareSection = styled.section`
  background-color: ${BACKGROUND_COLOR};
  padding: 4.5454em 0;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 768px) {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const HealthCare = () => {
  const [partners, setPartners] = useState([])

  useEffect(() => {
    loadInstitutes()
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const wow = require('wowjs')
      new wow.WOW({ live: false, mobile: false }).init()
    }
  })

  const loadInstitutes = async () => {
    try {
      const result = await getPartners()
      setPartners(result.data || [])
    } catch (error) {
      console.error(error)
    }
  }

  return (
    partners.length > 0 && (
      <HealthCareSection id="intezmenyek">
        <Container>
          <SectionTitle>Kiket Támogatunk</SectionTitle>
          <Grid>
            <Cards elements={partners} />
            <JoinCard text='Szükségem lenne támogatásra!' buttonLabel='Csatlakozom!'/>
          </Grid>
        </Container>
      </HealthCareSection>
    )
  )
}

export default HealthCare
